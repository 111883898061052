import store from "@/store";

import { useRouter } from "@core/utils/utils";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useCustomersCreate() {
  const { router } = useRouter();
  const toast = useToast();

  const createCustomer = (newCompany) => {
    store
      .dispatch("app-customer/createCustomer", { newCompany })
      .then(() => {
        toast(
          {
            component: ToastificationContent,
            props: {
              title: "Form Submitted",
              icon: "EditIcon",
              variant: "success",
            },
          },
          { position: "top-center" }
        );
        // redirect to list page
        router.push({ name: "customer-list" });
      })
      .catch(() => {
        toast(
          {
            component: ToastificationContent,
            props: {
              title: "Error creating Customer",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          },
          { position: "top-center" }
        );
      });
  };

  return {
    createCustomer,
  };
}
