import Vue from "vue";
import * as Api from "../../axios-generated/backoffice/";
// @ts-ignore
import axios from "@axios";

const COMPANIES_API = new Api.CompaniesApi(
  undefined,
  Vue.prototype.$config.VUE_APP_BACKOFFICE_API_BASE_URL,
  axios
);

const PROJECTS_API = new Api.ProjectsApi(
  undefined,
  Vue.prototype.$config.VUE_APP_BACKOFFICE_API_BASE_URL,
  axios
);

const CONTACTS_API = new Api.ContactsApi(
  undefined,
  Vue.prototype.$config.VUE_APP_BACKOFFICE_API_BASE_URL,
  axios
);

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // USERS
    fetchUsersByCompanyID(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        COMPANIES_API.findUsersByCompanyID(
          queryParams.filterCompanyId,
          queryParams.sortBy,
          queryParams.perPage,
          queryParams.page,
          queryParams.sortDesc
        )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    // CUSTOMERS
    fetchCustomers() {
      return new Promise((resolve, reject) => {
        COMPANIES_API.findCompanies()
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchCustomerByID(ctx, { customerID }) {
      return new Promise((resolve, reject) => {
        COMPANIES_API.findCompanyByID(customerID)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    createCustomer(ctx, { newCompany }) {
      return new Promise((resolve, reject) => {
        COMPANIES_API.createCompany(newCompany)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    editCustomer(ctx, { editCompany }) {
      return new Promise((resolve, reject) => {
        COMPANIES_API.updateCompany(editCompany.ID, editCompany)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    // PROJECTS
    findProjectsByCompanyID(ctx, { companyID }) {
      return new Promise((resolve, reject) => {
        COMPANIES_API.findProjectsByCompanyID(companyID)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    createProject(ctx, { newProject }) {
      return new Promise((resolve, reject) => {
        PROJECTS_API.createProject(newProject)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    editProject(ctx, { updateProject }) {
      return new Promise((resolve, reject) => {
        PROJECTS_API.updateProject(updateProject.ID, updateProject)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    findProjectByID(ctx, { projectID }) {
      return new Promise((resolve, reject) => {
        PROJECTS_API.findProjectByID(projectID)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteProjectByID(ctx, { projectID }) {
      return new Promise((resolve, reject) => {
        PROJECTS_API.deleteProjectByID(projectID)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    // CONTACTS
    createContact(ctx, { newContact }) {
      return new Promise((resolve, reject) => {
        CONTACTS_API.createContact(newContact)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    editContact(ctx, { updateContact }) {
      return new Promise((resolve, reject) => {
        CONTACTS_API.updateContact(updateContact.ID, updateContact)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    findContactByID(ctx, { contactID }) {
      return new Promise((resolve, reject) => {
        CONTACTS_API.findContactByID(contactID)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteContactByID(ctx, { contactID }) {
      return new Promise((resolve, reject) => {
        CONTACTS_API.deleteContactByID(contactID)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
