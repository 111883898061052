


















































































































































































































































































































































































import Vue from "vue";

// Typescript Files
import * as Api from "@/axios-generated/backoffice/index";

import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import {
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import { required, email, url } from "@validations";
import useCustomersCreate from "./useCustomersCreate";

export default Vue.extend({
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
  },
  data() {
    return {
      required,
      email,
      url,
      publicPath: process.env.BASE_URL,
      countryName: [
        { value: "select_value", text: "Select Value" },
        { value: "France", text: "France" },
        { value: "Canada", text: "Canada" },
        { value: "China", text: "China" },
        { value: "United States", text: "United States" },
        { value: "Brazil", text: "Brazil" },
        { value: "Australia", text: "Australia" },
        { value: "India", text: "India" },
      ],
      statusOptions: [
        { value: "select_value", text: "Select Value" },
        {
          value: Api.CompanyStatusEnum.Created,
          text: Api.CompanyStatusEnum.Created,
        },
        {
          value: Api.CompanyStatusEnum.Enabled,
          text: Api.CompanyStatusEnum.Enabled,
        },
        {
          value: Api.CompanyStatusEnum.Suspended,
          text: Api.CompanyStatusEnum.Suspended,
        },
        {
          value: Api.CompanyStatusEnum.Disabled,
          text: Api.CompanyStatusEnum.Disabled,
        },
      ],
      businessDomainOptions: [
        { value: "select_value", text: "Select Value" },
        {
          value: Api.CompanyBusinessDomainEnum.None,
          text: Api.CompanyBusinessDomainEnum.None,
        },
        {
          value: Api.CompanyBusinessDomainEnum.Bank,
          text: Api.CompanyBusinessDomainEnum.Bank,
        },
        {
          value: Api.CompanyBusinessDomainEnum.Insurance,
          text: Api.CompanyBusinessDomainEnum.Insurance,
        },
      ],
    };
  },
  props: {
    newCompany: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { createCustomer } = useCustomersCreate();

    const customerImageOptions = require
      .context("/public/images/customers", true, /^.*\.jpg$/)
      .keys()
      .map((val) => val.replace("./", ""));

    return {
      createCustomer,
      customerImageOptions,
    };
  },
  methods: {
    submitForm(newCompany) {
      this.$bvModal
        .msgBoxConfirm("Voulez-vous confirmer l'opération ?", {
          size: "sm",
          okVariant: "primary",
          okTitle: "Oui",
          cancelTitle: "Non",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.createCustomer(newCompany);
          }
        });
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
  },
});
