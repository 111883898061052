<template>
  <b-row class="mt-1">
    <b-col cols="12">
      <customer-create-form-wizard :new-company="newCompany" />
    </b-col>
  </b-row>
</template>

<script>
import { PageBoxed } from "@/views/utils/PageBoxed.js";
import { ref, onUnmounted } from "@vue/composition-api";
import { BRow, BCol } from "bootstrap-vue";
import CustomerCreateFormWizard from "./CustomerCreateFormWizard.vue";
import customerStoreModule from "../customerStoreModule";
import store from "@/store";

export default {
  components: {
    BRow,
    BCol,
    CustomerCreateFormWizard,
  },
  mixins: [PageBoxed],
  setup() {
    const newCompany = ref({});

    const CUSTOMER_APP_STORE_MODULE_NAME = "app-customer";

    // Register module
    if (!store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME))
      store.registerModule(CUSTOMER_APP_STORE_MODULE_NAME, customerStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME))
        store.unregisterModule(CUSTOMER_APP_STORE_MODULE_NAME);
    });

    return {
      newCompany,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
