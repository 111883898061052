var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('form-wizard',{staticClass:"mb-3",attrs:{"color":"#7367F0","title":null,"subtitle":null,"shape":"square","finish-button-text":"Submit","back-button-text":"Previous"},on:{"on-complete":function($event){return _vm.submitForm(_vm.newCompany)}}},[_c('tab-content',{attrs:{"title":"Client","before-change":_vm.validationForm}},[_c('validation-observer',{ref:"accountRules",attrs:{"tag":"form"}},[_c('b-row',{staticClass:"ml-2 mb-2"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"2"}},[_c('b-avatar',{attrs:{"src":"" + _vm.publicPath +
                    "images/customers/" +
                    "" + (_vm.newCompany.logo),"size":"104px","rounded":""}})],1),_c('b-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"mb-1"},[_c('h4',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.newCompany.name)+" ")])]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Logo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Logo","label-for":"Logo","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"logo","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.customerImageOptions},model:{value:(_vm.newCompany.logo),callback:function ($$v) {_vm.$set(_vm.newCompany, "logo", $$v)},expression:"newCompany.logo"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1)],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nom","label-for":"nom"}},[_c('validation-provider',{attrs:{"name":"Nom","vid":"Nom","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false : null,"placeholder":"MyCompany"},model:{value:(_vm.newCompany.name),callback:function ($$v) {_vm.$set(_vm.newCompany, "name", $$v)},expression:"newCompany.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Code","label-for":"code"}},[_c('validation-provider',{attrs:{"name":"Code","vid":"Code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"code","state":errors.length > 0 ? false : null,"placeholder":"MCY"},model:{value:(_vm.newCompany.code),callback:function ($$v) {_vm.$set(_vm.newCompany, "code", $$v)},expression:"newCompany.code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Description","label-for":"description"}},[_c('validation-provider',{attrs:{"name":"Description","vid":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"description","state":errors.length > 0 ? false : null,"placeholder":"My description."},model:{value:(_vm.newCompany.description),callback:function ($$v) {_vm.$set(_vm.newCompany, "description", $$v)},expression:"newCompany.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Url","label-for":"url"}},[_c('validation-provider',{attrs:{"name":"Url","vid":"Url","rules":"required|url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"url","type":"url","state":errors.length > 0 ? false : null,"placeholder":"https://mycompany.fr"},model:{value:(_vm.newCompany.URL),callback:function ($$v) {_vm.$set(_vm.newCompany, "URL", $$v)},expression:"newCompany.URL"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Enregistrement","label-for":"enregistrement"}},[_c('validation-provider',{attrs:{"name":"Enregistrement","vid":"Enregistrement","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"registration_number","state":errors.length > 0 ? false : null,"placeholder":"654646G4545"},model:{value:(_vm.newCompany.registration_number),callback:function ($$v) {_vm.$set(_vm.newCompany, "registration_number", $$v)},expression:"newCompany.registration_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Numéro TVA","label-for":"numéro TVA"}},[_c('validation-provider',{attrs:{"name":"Numéro TVA","vid":"Numéro TVA","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"vat","state":errors.length > 0 ? false : null,"placeholder":"FR 56474747"},model:{value:(_vm.newCompany.vat),callback:function ($$v) {_vm.$set(_vm.newCompany, "vat", $$v)},expression:"newCompany.vat"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Statut","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Statut","label-for":"Statut","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"status","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.statusOptions,"reduce":function (val) { return val.value; },"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text"},model:{value:(_vm.newCompany.status),callback:function ($$v) {_vm.$set(_vm.newCompany, "status", $$v)},expression:"newCompany.status"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Business Domain","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Business Domain","label-for":"business_domain","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"business_domain","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.businessDomainOptions,"reduce":function (val) { return val.value; },"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text"},model:{value:(_vm.newCompany.business_domain),callback:function ($$v) {_vm.$set(_vm.newCompany, "business_domain", $$v)},expression:"newCompany.business_domain"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1)],1)],1),_c('tab-content',{attrs:{"title":"Contact","before-change":_vm.validationFormInfo}},[_c('validation-observer',{ref:"infoRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v("Contact Client")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Contact légal","label-for":"contact légal"}},[_c('validation-provider',{attrs:{"name":"Contact légal","vid":"Contact légal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"contact_legal","state":errors.length > 0 ? false : null,"placeholder":"Martin Dupont"},model:{value:(_vm.newCompany.contact_legal),callback:function ($$v) {_vm.$set(_vm.newCompany, "contact_legal", $$v)},expression:"newCompany.contact_legal"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Contact email","label-for":"contact email"}},[_c('validation-provider',{attrs:{"name":"Contact email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","type":"email","state":errors.length > 0 ? false : null,"placeholder":"martin.dupont@email.com"},model:{value:(_vm.newCompany.email),callback:function ($$v) {_vm.$set(_vm.newCompany, "email", $$v)},expression:"newCompany.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Adresse 1","label-for":"adresse 1"}},[_c('validation-provider',{attrs:{"name":"Adresse 1","vid":"Adresse 1","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"address_1","state":errors.length > 0 ? false : null,"placeholder":"Bat 12,"},model:{value:(_vm.newCompany.address_1),callback:function ($$v) {_vm.$set(_vm.newCompany, "address_1", $$v)},expression:"newCompany.address_1"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Adresse 2","label-for":"adresse 2"}},[_c('validation-provider',{attrs:{"name":"Adresse 2","vid":"Adresse 2","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"address_2","state":errors.length > 0 ? false : null,"placeholder":"Bat 12,"},model:{value:(_vm.newCompany.address_2),callback:function ($$v) {_vm.$set(_vm.newCompany, "address_2", $$v)},expression:"newCompany.address_2"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Code postal","label-for":"code postal"}},[_c('validation-provider',{attrs:{"name":"Code postal","vid":"Code postal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"zip_code","state":errors.length > 0 ? false : null,"placeholder":"69300"},model:{value:(_vm.newCompany.zip_code),callback:function ($$v) {_vm.$set(_vm.newCompany, "zip_code", $$v)},expression:"newCompany.zip_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Pays","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Pays","label-for":"pays","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"country","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.countryName,"reduce":function (val) { return val.value; },"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text"},model:{value:(_vm.newCompany.country),callback:function ($$v) {_vm.$set(_vm.newCompany, "country", $$v)},expression:"newCompany.country"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }